<template>
  <div class="bar-code-wrapper page">
    <el-card class="box-card box-control box-control-flex box-control-flex-column">
      <div class="box-checkbox display-flex margin-bottom-10">
        <el-checkbox v-model="isPrintOnlyOne">
          Печать для одного товара
        </el-checkbox>
      </div>

      <div class="display-flex">
        <label class="main-label">
          <span class="main-label__title">Категории товаров</span>

          <el-select
            v-model="selectedCategoryId"
            placeholder="Выберите категории товаров"
            @change="loadProductsById"
          >
            <el-option
              v-for="item in getDataCategories"
              :key="item.value"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </label>

        <label
          v-if="!printAllProducts"
          class="main-label"
        >
          <span class="main-label__title">Товары</span>

          <el-select
            v-model="selectedProductsId"
            :multiple="!isPrintOnlyOne"
            filterable
            clearable
            collapse-tags
            placeholder="Выберите товары"
          >
            <el-option
              v-for="item in loadedProductsData"
              :key="item.value"
              :label="item.titleWithCode"
              :value="item.id"
            />
          </el-select>
        </label>

        <label
          v-if="isPrintOnlyOne"
          class="main-label"
        >
          <span class="main-label__title">Кол. этикеток</span>

          <el-input
            v-model="printCount"
            type="text"
            label="Кол. этикеток"
            placeholder="Кол. этикеток"
          />
        </label>
      </div>

      <div class="box-checkbox display-flex margin-top-10">
        <el-checkbox
          v-if="!isPrintOnlyOne"
          v-model="printAllProducts"
          @change="setAllSelectedProducts"
        >
          Печать всех товаров из категории
        </el-checkbox>

        <el-checkbox v-model="isPrintBarCode">
          Печать ШК
        </el-checkbox>
      </div>

      <el-button
        class="margin-top-10 el-button-h-40"
        plain
        type="primary"
        :disabled="isEmpty(selectedProductsId)"
        @click="print"
      >
        <span>Печать</span>
      </el-button>
    </el-card>

    <el-card v-if="isShowPrintBlockOneProduct">
      <div
        id="print"
        class="bar-code-table"
      >
        <div
          v-for="i in Array.from(Array(Number(printCount)).keys())"
          :key="new Date().getTime() + selectedProductsId + i + 1"
          class="bar-code-table_block"
        >
          <div class="table-info">
            <div class="table-info__title">{{ ORGANIZATION_NAME }}</div>
            <div class="table-info__content">
              <div class="table-info__title-product">{{ loadedProductsDataObject[selectedProductsId].title }}</div>

              <template
                v-if="isSalePriceInProductId(selectedProductsId, loadedProductsDataObject)"
              >
                <div  class="table-info__title-new-price">Новая цена: <b>{{ getPriceToFormat(loadedProductsDataObject[selectedProductsId].salePrice) }} р.</b></div>
                <div class="table-info__title-old-price">Старая цена: <b>{{ getPriceToFormat(loadedProductsDataObject[selectedProductsId].price) }} р.</b></div>
              </template>

              <div v-else class="table-info__title-price">Цена: <b>{{ getPriceToFormat(loadedProductsDataObject[selectedProductsId].price) }} р.</b></div>
            </div>
          </div>
        </div>

        <div
          v-for="i in Array.from(Array(Number(printCount)).keys())"
          :key="new Date().getTime() + selectedProductsId + i + 2"
          class="bar-code-table_block bar-code-table_block__barcode"
        >
          <vue-barcode
            v-if="isPrintBarCode"
            class="table-bar-code"
            :value="loadedProductsDataObject[selectedProductsId].code"
            tag="img"
          />
        </div>
      </div>
    </el-card>

    <el-card v-else-if="isShowPrintBlockManyProduct">
      <div id="print" class="bar-code-table">
        <template
          v-for="(item) in selectedProductsId"
        >
          <div
            v-for="i in Math.sign(loadedProductsDataObject[item].count) === -1 ? 1 : loadedProductsDataObject[item].count || 1"
            :key="new Date().getTime() + loadedProductsDataObject[item].id + i + 1"
            class="bar-code-table_block bar-code-table_block__info"
          >
            <div class="table-info">
              <div class="table-info__title">{{ ORGANIZATION_NAME }}</div>
              <div class="table-info__main">
                <div class="table-info__title-product">{{ loadedProductsDataObject[item].title }}</div>

                <template
                  v-if="isSalePriceInProductId(item, loadedProductsDataObject)"
                >
                  <div  class="table-info__title-new-price">Новая цена: <b>{{ getPriceToFormat(loadedProductsDataObject[item].salePrice) }} р.</b></div>
                  <div class="table-info__title-old-price">Старая цена: <b>{{ getPriceToFormat(loadedProductsDataObject[item].price) }} р.</b></div>
                </template>

                <div v-else class="table-info__title-price">Цена: <b>{{ getPriceToFormat(loadedProductsDataObject[item].price) }} р.</b></div>
              </div>
            </div>
          </div>

          <div
            v-for="i in Math.sign(loadedProductsDataObject[item].count) === -1 ? 1 : loadedProductsDataObject[item].count || 1"
            :key="new Date().getTime() + loadedProductsDataObject[item].id + i + 2"
            class="bar-code-table_block bar-code-table_block__barcode"
          >
            <vue-barcode
              v-if="isPrintBarCode"
              class="table-bar-code"
              :value="loadedProductsDataObject[item].code"
              tag="img"
            />
          </div>
        </template>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vueBarcode from 'vue-barcode'
import isEmpty from 'lodash/isEmpty'
import { isSalePriceInProductId, getPriceToFormat } from '@/helper'

const ORGANIZATION_NAME = 'ИП Амириллаева С.М.'

export default {
  name: 'BarCodes',
  components: {
    vueBarcode
  },
  data () {
    return {
      loadedProductsData: [],
      loadedProductsDataObject: {},
      selectedCategoryId: null,
      selectedProductsId: null,
      printCount: 1,
      isPrintBarCode: true,
      printAllProducts: false,
      isPrintOnlyOne: false,
      isEmpty,
      ORGANIZATION_NAME
    }
  },
  computed: {
    ...mapGetters('categories', { getDataCategories: 'getData' }),
    isShowPrintBlockOneProduct () {
      return this.isPrintOnlyOne && !isEmpty(this.selectedProductsId)
    },
    isShowPrintBlockManyProduct () {
      return !this.isPrintOnlyOne && !isEmpty(this.selectedProductsId)
    },
  },
  async created () {
    await this.loadData()
  },
  watch: {
    isPrintOnlyOne () {
      this.selectedCategoryId = null
      this.selectedProductsId = null
      this.loadedProductsData = []
      this.printCount = 1
    }
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('categories', { loadDataCategories: 'loadData' }),
    ...mapActions('products', {
      loadDataProducts: 'loadData',
      getDataByCategoriesId: 'getDataByCategoriesId'
    }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataCategories()
      await this.loadDataProducts()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    isSalePriceInProductId,
    getPriceToFormat,
    async loadProductsById () {
      const data = await this.getDataByCategoriesId(this.selectedCategoryId) || []
      this.loadedProductsData = data

      data.forEach(item => {
        this.loadedProductsDataObject[item.id] = item
      })
    },
    print () {
      const mywindow = window.open("", "printMe", "height=600, width=1000")

      // TODO: КОСТЫЛЬ
      mywindow.document.write("<style>.bar-code-table { display: flex; flex-direction: row; align-items: flex-start; flex-wrap: wrap; font-size: 10px; } .bar-code-table_block { page-break-before: always; } .table-info { display: flex; flex-direction: column; align-items: flex-start; width: 4cm; height: 2cm; border: 1px solid #000; font-size: 12px; margin-top: 5px; } .table-info__title { width: 100%; background-color: #000; color: #fff; text-align: center; border-bottom: 1px solid #000; } .table-info__content { width: 100%; } .table-info__main { display: flex; flex-direction: column; justify-content: space-between; height: 100%; } .table-info__title-product { overflow: hidden; font-weight: bold; font-size: 12px; width: 3.9cm; } .table-info__title-old-price { font-size: 10px; text-decoration: line-through; } .table-info__title-new-price { font-size: 12px; } .table-bar-code { display: flex; align-items: center; justify-content: center; width: 4cm; height: 2cm; } td, th { padding: 3px; }</style>")
      mywindow.document.write(document.getElementById('print').outerHTML)
      mywindow.document.close()
      mywindow.focus()
      mywindow.print()
      mywindow.close()
      return true;
    },
    setAllSelectedProducts () {
      this.selectedProductsId = this.loadedProductsData.map(({ id }) => id) || []
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />